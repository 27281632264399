<template>
  <v-container fluid v-if="!loading">
    <div class="d-flex" style="border-bottom: 1px solid var(--v-secondary-base);border-bottom-right-radius: 30px;width: 100%;">
      <v-col class="pa-0 me-1">
        <v-btn class="mb-1" @click="setVentas" style="width:100%" color="primary">GUARDAR <v-icon>mdi-floppy</v-icon></v-btn>
        <v-date-picker :max="new Date().toISOString().slice(0, 10)" :value="date" @change="changeDate(date,$event)" style="border-bottom-right-radius: 10px;border-right: 3px solid var(--v-primary-base);width: min-content;height: min-content"></v-date-picker>
      </v-col>
      <div style="width: 100%">
        <div style="width: 100%;">
          <v-simple-table>
            <thead>
              <tr>
                <th class="text-center primary--text" style="font-size: large;" :colspan="espacios.length+2">
                  VENTAS
                </th>
              </tr>
              <tr>
                <th></th>
                <th class="text-center" style="white-space: nowrap;color: var(--v-primary-base)" v-for="espacio in espacios" :key="espacio.idEspacio">{{ espacio.nombre }}</th>
                <th class="text-center" style="white-space: nowrap;">TOTAL</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="font-weight-bold">VENTAS</td>
                <td class="text-center" v-for="espacio in espacios" :key="espacio.idEspacio"><v-text-field :value="modelo.find(esp => esp.fecha == date && esp.idEspacio == espacio.idEspacio)?.ventas" @focus="$event.target.select()" placeholder="0" type="number" hide-details="" @input="change(espacio.idEspacio,'ventas',$event)" class="pt-0 mx-auto centered-input" style="width:50px"></v-text-field></td>
                <td class="text-center">{{ modelo.reduce((acc,val) => parseFloat(acc) + parseFloat(val.ventas || 0),0) }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">PRESUPUESTO MES</td>
                <td class="text-center" v-for="espacio in espacios" :key="espacio.idEspacio"><v-text-field  @focus="$event.target.select()" placeholder="0" type="number" hide-details="" :value="pptos.find(ppto => ppto.idEspacio == espacio && ppto.fecha == new Date(date).getFullYear() + '-' + (('0'+new Date(date).getMonth()).slice(-2)))?.ppto" @input="changePpto(espacio.idEspacio,$event)" class="pt-0 mx-auto centered-input" style="width:50px"></v-text-field></td>
                <td class="text-center">{{ pptos.filter(p => new Date(p.fecha).getMonth() == new Date(date).getMonth() && new Date(p.fecha).getFullYear() == new Date(date).getFullYear()).reduce((acc,val) => parseFloat(acc) + parseFloat(val.ppto || 0),0) }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">PRESUPUESTO MES ACUMULADO</td>
                <td class="text-center" v-for="espacio in espacios" :key="espacio.idEspacio">{{ parseFloat(pptos.some(ppto => ppto.fecha == new Date(date).getFullYear() + '-' + (('0'+new Date(date).getMonth()).slice(-2)) && ppto.idEspacio == espacio.idEspacio) ? ((pptos.find(ppto => ppto.fecha == new Date(date).getFullYear() + '-' + (('0'+new Date(date).getMonth()).slice(-2)) && ppto.idEspacio == espacio.idEspacio)?.ppto || 0) / (days || 1) * new Date(date).getDate()) : 0).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</td>
                <td class="text-center">{{ parseFloat((pptos.filter(ppto => ppto.fecha == new Date(date).getFullYear() + '-' + (('0'+new Date(date).getMonth()).slice(-2))).reduce((acc,val) => parseFloat(acc) + parseFloat(val.ppto),0)) / (days || 1) * new Date(date).getDate()).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</td>
              </tr>
              <tr>
                <td style="white-space: nowrap;">TICKET MEDIO ACUMULADO</td>
                <td class="text-center primary--text font-weight-black" style="white-space: nowrap;font-size:large" v-for="espacio in espacios" :key="espacio.idEspacio">{{ parseFloat((modelo.find(mod => mod.idEspacio == espacio.idEspacio && mod.fecha == date)?.ventas || 0) / (modelo.find(mod => mod.idEspacio == espacio.idEspacio && mod.fecha == date)?.clientes || 1)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</td>
                <td class="text-center primary--text font-weight-black" style="white-space: nowrap;font-size:large">{{ parseFloat(modelo.reduce((acc,val) => acc + (val.pptoMesAcc || 0),0)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th class="text-center primary--text" style="font-size: large;" :colspan="espacios.length+2">
                  OPERATIVA
                </th>
              </tr>
              <tr>
                <th></th>
                <th class="text-center" style="white-space: nowrap;color: var(--v-primary-base)" v-for="espacio in espacios" :key="espacio.idEspacio">{{ espacio.nombre }}</th>
                <th class="text-center" style="white-space: nowrap;">TOTAL</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>MENÚ DIARIO (15,50 / 20,00 €)</th>
                <td style="white-space: nowrap;" v-for="espacio in espacios" :key="espacio.idEspacio"><v-text-field :value="modelo.find(esp => esp.fecha == date && esp.idEspacio == espacio.idEspacio)?.menuDiario" @focus="$event.target.select()" placeholder="0" type="number" hide-details="" @input="change(espacio.idEspacio,'menuDiario',$event)" class="pt-0 mx-auto centered-input" style="width:50px"></v-text-field></td>
                <td class="text-center">{{ parseFloat(modelo.reduce((acc,val) => acc + parseFloat(val.menuDiario || 0),0)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</td>
              </tr>
              <tr>
                <th>CARTAS</th>
                <td style="white-space: nowrap;" v-for="espacio in espacios" :key="espacio.idEspacio"><v-text-field :value="modelo.find(esp => esp.fecha == date && esp.idEspacio == espacio.idEspacio)?.carta" @focus="$event.target.select()" placeholder="0" type="number" hide-details="" @input="change(espacio.idEspacio,'carta',$event)" class="pt-0 mx-auto centered-input" style="width:50px"></v-text-field></td>
                <td class="text-center">{{ parseFloat(modelo.reduce((acc,val) => acc + parseFloat(val.carta || 0),0)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g,".") }}</td>
              </tr>
              <tr>
                <th>PARA LLEVAR</th>
                <td style="white-space: nowrap;" v-for="espacio in espacios" :key="espacio.idEspacio"><v-text-field :value="modelo.find(esp => esp.fecha == date && esp.idEspacio == espacio.idEspacio)?.paraLlevar" @focus="$event.target.select()" placeholder="0" type="number" hide-details="" @input="change(espacio.idEspacio,'paraLlevar',$event)" class="pt-0 mx-auto centered-input" style="width:50px"></v-text-field></td>
                <td class="text-center">{{ parseFloat(modelo.reduce((acc,val) => acc + parseFloat(val.paraLlevar || 0),0)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g,".") }}</td>
              </tr>
              <tr>
                <th>MENÚ LIGERO (ALOJADOS LN a JV)</th>
                <td style="white-space: nowrap;" v-for="espacio in espacios" :key="espacio.idEspacio"><v-text-field :value="modelo.find(esp => esp.fecha == date && esp.idEspacio == espacio.idEspacio)?.menuLigero" @focus="$event.target.select()" placeholder="0" type="number" hide-details="" @input="change(espacio.idEspacio,'menuLigero',$event)" class="pt-0 mx-auto centered-input" style="width:50px"></v-text-field></td>
                <td class="text-center">{{ parseFloat(modelo.reduce((acc,val) => acc + parseFloat(val.menuLigero || 0),0)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g,".") }}</td>
              </tr>
              <tr>
                <th>MENÚ DEGUSTACIÓN (FINDE) / ESPECIALES</th>
                <td style="white-space: nowrap;" v-for="espacio in espacios" :key="espacio.idEspacio"><v-text-field :value="modelo.find(esp => esp.fecha == date && esp.idEspacio == espacio.idEspacio)?.menuEspecial" @focus="$event.target.select()" placeholder="0" type="number" hide-details="" @input="change(espacio.idEspacio,'menuEspecial',$event)" class="pt-0 mx-auto centered-input" style="width:50px"></v-text-field></td>
                <td class="text-center">{{ parseFloat(modelo.reduce((acc,val) => acc + parseFloat(val.menuEspecial || 0),0)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g,".") }}</td>
              </tr>
              <tr>
                <th>CENAS SAM (ALOJADOS)</th>
                <td style="white-space: nowrap;" v-for="espacio in espacios" :key="espacio.idEspacio"><v-text-field :value="modelo.find(esp => esp.fecha == date && esp.idEspacio == espacio.idEspacio)?.cenasSam" @focus="$event.target.select()" placeholder="0" type="number" hide-details="" @input="change(espacio.idEspacio,'cenasSam',$event)" class="pt-0 mx-auto centered-input" style="width:50px"></v-text-field></td>
                <td class="text-center">{{ parseFloat(modelo.reduce((acc,val) => acc + parseFloat(val.cenasSam || 0),0)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g,".") }}</td>
              </tr>
              <tr>
                <th>CENAS SAM (NO ALOJADOS)</th>
                <td style="white-space: nowrap;" v-for="espacio in espacios" :key="espacio.idEspacio"><v-text-field :value="modelo.find(esp => esp.fecha == date && esp.idEspacio == espacio.idEspacio)?.cenasSamNo" @focus="$event.target.select()" placeholder="0" type="number" hide-details="" @input="change(espacio.idEspacio,'cenasSamNo',$event)" class="pt-0 mx-auto centered-input" style="width:50px"></v-text-field></td>
                <td class="text-center">{{ parseFloat(modelo.reduce((acc,val) => acc + parseFloat(val.cenasSamNo || 0),0)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g,".") }}</td>
              </tr>
              <tr>
                <th style="border-bottom: 1px solid var(--v-primary-base);">TOTAL CLIENTES</th>
                <td style="white-space: nowrap;border-bottom: 1px solid var(--v-primary-base);" v-for="espacio in espacios" :key="espacio.idEspacio"><v-text-field :value="modelo.find(esp => esp.fecha == date && esp.idEspacio == espacio.idEspacio)?.clientes" @focus="$event.target.select()" placeholder="0" type="number" hide-details="" @input="change(espacio.idEspacio,'clientes',$event)" class="pt-0 mx-auto centered-input" style="width:50px"></v-text-field></td>
                <td style="border-bottom: 1px solid var(--v-primary-base);" class="text-center">{{ parseFloat(modelo.reduce((acc,val) => acc + parseFloat(val.clientes || 0),0)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g,".") }}</td>
              </tr>
              <tr>
                <th>ALOJADOS</th>
                <td style="white-space: nowrap;" v-for="espacio in espacios" :key="espacio.idEspacio"><v-text-field :value="modelo.find(esp => esp.fecha == date && esp.idEspacio == espacio.idEspacio)?.alojados" @focus="$event.target.select()" placeholder="0" type="number" hide-details="" @input="change(espacio.idEspacio,'alojados',$event)" class="pt-0 mx-auto centered-input" style="width:50px"></v-text-field></td>
                <td class="text-center">{{ parseFloat(modelo.reduce((acc,val) => acc + parseFloat(val.alojados || 0),0)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g,".") }}</td>
              </tr>
              <tr>
                <th style="border-bottom: 1px solid var(--v-primary-base);">F&B GUEST</th>
                <td style="white-space: nowrap;border-bottom: 1px solid var(--v-primary-base);" v-for="espacio in espacios" :key="espacio.idEspacio"><v-text-field :value="modelo.find(esp => esp.fecha == date && esp.idEspacio == espacio.idEspacio)?.fnbGuest" @focus="$event.target.select()" placeholder="0" type="number" hide-details="" @input="change(espacio.idEspacio,'fnbGuest',$event)" class="pt-0 mx-auto centered-input" style="width:50px"></v-text-field></td>
                <td style="border-bottom: 1px solid var(--v-primary-base);" class="text-center">{{ parseFloat(modelo.reduce((acc,val) => acc + parseFloat(val.fnbGuest || 0),0)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g,".") }}</td>
              </tr>
              <tr>
                <th>Nº CAMAREROS</th>
                <td style="white-space: nowrap;" v-for="espacio in espacios" :key="espacio.idEspacio"><v-text-field :value="modelo.find(esp => esp.fecha == date && esp.idEspacio == espacio.idEspacio)?.camareros" @focus="$event.target.select()" placeholder="0" type="number" hide-details="" @input="change(espacio.idEspacio,'camareros',$event)" class="pt-0 mx-auto centered-input" style="width:50px"></v-text-field></td>
                <td class="text-center">{{ parseFloat(modelo.reduce((acc,val) => acc + parseFloat(val.camareros || 0),0)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g,".") }}</td>
              </tr>
              <tr>
                <th>Nº COCINEROS</th>
                <td style="white-space: nowrap;" v-for="espacio in espacios" :key="espacio.idEspacio"><v-text-field :value="modelo.find(esp => esp.fecha == date && esp.idEspacio == espacio.idEspacio)?.cocineros" @focus="$event.target.select()" placeholder="0" type="number" hide-details="" @input="change(espacio.idEspacio,'cocineros',$event)" class="pt-0 mx-auto centered-input" style="width:50px"></v-text-field></td>
                <td class="text-center">{{ parseFloat(modelo.reduce((acc,val) => acc + parseFloat(val.cocineros || 0),0)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g,".") }}</td>
              </tr>
              <tr>
                <th>VENTAS POR CAMARERO</th>
                <td class="text-center primary--text font-weight-black" style="white-space: nowrap;font-size:large" v-for="espacio in espacios" :key="espacio.idEspacio">{{ parseFloat((modelo.find(mod => mod.idEspacio == espacio.idEspacio && mod.fecha == date)?.ventas || 0) / (modelo.find(mod => mod.idEspacio == espacio.idEspacio && mod.fecha == date)?.camareros || 1)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</td>
                <td class="text-center primary--text font-weight-black" style="white-space: nowrap;font-size:large">{{ parseFloat(modelo.reduce((acc,val) => acc + parseFloat(val.ventas / (val.camareros || 1) || 0),0)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</td>
              </tr>
              <tr>
                <th>VENTAS POR COCINERO</th>
                <td class="text-center primary--text font-weight-black" style="white-space: nowrap;font-size:large" v-for="espacio in espacios" :key="espacio.idEspacio">{{ parseFloat((modelo.find(mod => mod.idEspacio == espacio.idEspacio && mod.fecha == date)?.ventas || 0) / (modelo.find(mod => mod.idEspacio == espacio.idEspacio && mod.fecha == date)?.cocineros || 1)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</td>
                <td class="text-center primary--text font-weight-black" style="white-space: nowrap;font-size:large">{{ parseFloat(modelo.reduce((acc,val) => acc + parseFloat(val.ventas / (val.cocineros || 1) || 0),0)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</td>
              </tr>
              <tr>
                <th>CLIENTES POR CAMARERO</th>
                <td class="text-center primary--text font-weight-black" style="white-space: nowrap;font-size:large" v-for="espacio in espacios" :key="espacio.idEspacio">{{ parseFloat((modelo.find(mod => mod.idEspacio == espacio.idEspacio && mod.fecha == date)?.clientes || 0) / (modelo.find(mod => mod.idEspacio == espacio.idEspacio && mod.fecha == date)?.camareros || 1)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</td>
                <td class="text-center primary--text font-weight-black" style="white-space: nowrap;font-size:large">{{ parseFloat(modelo.reduce((acc,val) => acc + parseFloat(val.clientes / (val.camareros || 1) || 0),0)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</td>
              </tr>
              <!-- <tr>
                <td class="font-weight-bold">{{ item.title }}</td>
                <td v-for="espacio in espacios" :key="espacio.idEspacio"><v-text-field @focus="$event.target.select()" placeholder="0" type="number" hide-details="" @input="change(espacio.idEspacio,item.key,$event)" class="pt-0 mx-auto centered-input" ></v-text-field></td>
              </tr> -->
            </tbody>
          </v-simple-table>
        </div>
      </div>
    </div>
    <div>
      <v-simple-table>
        <thead>
          <tr>
            <th>
              <v-select :items="espacios" :item-text="item => item.nombre" :item-value="item => item.idEspacio" v-model="searchEspacio">
              </v-select>
            </th>
            <th v-for="d in days">{{ ('0' + d).slice(-2) }}</th>
          </tr>
        </thead>
        <tbody v-if="searchEspacio">
          <tr>
            <td>
              VENTAS
            </td>
            <td>
              {{ modelo.find() }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td :colspan="days+1">
              Escoge un espacio
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
    <div style="max-width: fit-content;position: fixed;right:20px; bottom: 20px;z-index:100000" bottom class="d-flex mt-2 ms-auto">
      <v-btn color="primary" fab @click="exportar" large class="me-4">
        <v-icon>mdi-microsoft-excel</v-icon>
        <!-- Añadir evento -->
      </v-btn>
    </div>
  </v-container>
  <v-container v-else>
    <div style="width: 100%;height: 90vh;" class="d-flex align-center justify-center">
      <span style="flex-direction: column;margin-top: -250px;" class="d-flex align-center justify-center">
        <v-progress-circular
          size="500"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <span style="margin-top: -270px; font-size: xx-large;font-weight: bold;">
          Cargando
        </span>
      </span>
    </div>
  </v-container>
</template>

<script>
// import { perColumnFilter } from "@/utils";
import FileSaver from 'file-saver';
import * as ExcelJS from "exceljs";

export default {
  components: {
    TableFilters: () => import("@/components/TableFilters.vue"),
  },
  data() {
    return {
      loading: true,
      searchEspacio: null,
      espacios:[
        {nombre : "1969 MAÑ", idEspacio : 0},
        {nombre : "1969 TAR", idEspacio : 1},
        {nombre : "SAM", idEspacio : 2},
        {nombre : "RTE PISCIS", idEspacio : 3},
        {nombre : "LC COMER", idEspacio : 4},
        {nombre : "LC CENAR", idEspacio : 5}
      ],
      cambios:[
      ],
      modelo:[
      ],
      pptos:[
      ],
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    };
  },
  async mounted(){
    this.loading = true
    await Promise.all([
      // this.getVentas()
    ]);
    this.loading = false
  },
  computed: {
    tercio(){
      var str = new Date(this.date).getFullYear() + '-' + ('0'+(new Date(this.date).getMonth()+1)).slice(-2) + '-'
      return [str + '10', str + '20', str + ('0' + this.days).slice(-2)]
    },
    days(){
      return new Date(new Date(this.date).getFullYear(), new Date(this.date).getMonth() +1, 0).getDate()
    },
  },
  methods: {
    exportar() {
      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet('OCUPACIÓN');

      var rows = [
        [null, "VENTAS"],
      ]
      var aux = [null,null]
      this.espacios.forEach(esp => {
        aux.push(esp.nombre)
      });
      aux.push("TOTAL")
      rows.push(aux)
      var aux = [null,"VENTAS"]
      this.espacios.forEach(espacio => {
        aux.push(parseFloat(this.modelo.find(esp => esp.fecha == this.date && esp.idEspacio == espacio.idEspacio)?.ventas || 0).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, "."))
      });
      aux.push(parseFloat(this.modelo.reduce((acc,val) => parseFloat(acc) + parseFloat(val.ventas || 0),0)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, "."))
      rows.push(aux)
      var aux = [null,"PRESUPUESTO MES"]
      this.espacios.forEach(espacio => {
        aux.push(parseFloat(this.pptos.find(ppto => ppto.idEspacio == espacio && ppto.fecha == new Date(this.date).getFullYear() + '-' + (('0'+new Date(this.date).getMonth()).slice(-2)))?.ppto || 0).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, "."))
      });
      aux.push(parseFloat(this.pptos.filter(p => new Date(p.fecha).getMonth() == new Date(date).getMonth() && new Date(p.fecha).getFullYear() == new Date(this.date).getFullYear()).reduce((acc,val) => parseFloat(acc) + parseFloat(val.ppto || 0),0)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, "."))
      rows.push(aux)
      var aux = [null,"PRESUPUESTO MES ACUMULADO"]
      this.espacios.forEach(espacio => {
        aux.push(parseFloat(this.pptos.some(ppto => ppto.fecha == new Date(this.date).getFullYear() + '-' + (('0'+new Date(this.date).getMonth()).slice(-2)) && ppto.idEspacio == espacio.idEspacio) ? ((this.pptos.find(ppto => ppto.fecha == new Date(this.date).getFullYear() + '-' + (('0'+new Date(this.date).getMonth()).slice(-2)) && this.ppto.idEspacio == espacio.idEspacio)?.ppto || 0) / (this.days || 1) * new Date(this.date).getDate()) : 0).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, "."))
      });
      aux.push(parseFloat((this.pptos.filter(ppto => ppto.fecha == new Date(this.date).getFullYear() + '-' + (('0'+new Date(this.date).getMonth()).slice(-2))).reduce((acc,val) => parseFloat(acc) + parseFloat(val.ppto),0)) / (this.days || 1) * new Date(this.date).getDate()).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, "."))
      rows.push(aux)
      var aux = [null,"TICKET MEDIO ACUMULADO"]
      this.espacios.forEach(espacio => {
        aux.push(parseFloat((this.modelo.find(mod => mod.idEspacio == espacio.idEspacio && mod.fecha == date)?.ventas || 0) / (this.modelo.find(mod => mod.idEspacio == espacio.idEspacio && mod.fecha == this.date)?.clientes || 1)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, "."))
      });
      aux.push(parseFloat(this.modelo.reduce((acc,val) => acc + (val.pptoMesAcc || 0),0)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, "."))
      rows.push(aux)
      var aux = [null,"OPERATIVA"]
      rows.push(aux)
      var aux = [null,null]
      this.espacios.forEach(esp => {
        aux.push(esp.nombre)
      });
      aux.push("TOTAL")
      rows.push(aux)
      var aux = [null,"MENÚ DIARIO (15,50 / 20,00 €)"]
      this.espacios.forEach(espacio => {
        aux.push(parseFloat(this.modelo.find(esp => esp.fecha == date && esp.idEspacio == espacio.idEspacio)?.menuDiario || 0).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, "."))
      });
      aux.push(parseFloat(this.modelo.reduce((acc,val) => acc + parseFloat(val.menuDiario || 0),0)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, "."))
      rows.push(aux)
      var aux = [null,"CARTAS"]
      this.espacios.forEach(espacio => {
        aux.push(parseFloat(this.modelo.find(esp => esp.fecha == this.date && esp.idEspacio == espacio.idEspacio)?.carta || 0).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, "."))
      });
      aux.push(parseFloat(this.modelo.reduce((acc,val) => acc + parseFloat(val.carta || 0),0)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g,"."))
      rows.push(aux)
      var aux = [null,"PARA LLEVAR"]
      this.espacios.forEach(espacio => {
        aux.push(parseFloat(this.modelo.find(esp => esp.fecha == this.date && esp.idEspacio == espacio.idEspacio)?.paraLlevar || 0).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, "."))
      });
      aux.push(parseFloat(this.modelo.reduce((acc,val) => acc + parseFloat(val.paraLlevar || 0),0)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g,"."))
      rows.push(aux)
      var aux = [null,"MENÚ LIGERO (ALOJADOS LN a JV)"]
      this.espacios.forEach(espacio => {
        aux.push(parseFloat(this.modelo.find(esp => esp.fecha == this.date && esp.idEspacio == espacio.idEspacio)?.menuLigero || 0).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, "."))
      });
      aux.push(parseFloat(this.modelo.reduce((acc,val) => acc + parseFloat(val.menuLigero || 0),0)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g,"."))
      rows.push(aux)
      var aux = [null,"MENÚ DEGUSTACIÓN (FINDE) / ESPECIALES"]
      this.espacios.forEach(espacio => {
        aux.push(parseFloat(this.modelo.find(esp => esp.fecha == this.date && esp.idEspacio == espacio.idEspacio)?.menuEspecial || 0).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, "."))
      });
      aux.push(parseFloat(this.modelo.reduce((acc,val) => acc + parseFloat(val.menuEspecial || 0),0)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g,"."))
      rows.push(aux)
      var aux = [null,"CENAS SAM (ALOJADOS)"]
      this.espacios.forEach(espacio => {
        aux.push(parseFloat(this.modelo.find(esp => esp.fecha == this.date && esp.idEspacio == espacio.idEspacio)?.cenasSam || 0).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, "."))
      });
      aux.push(parseFloat(this.modelo.reduce((acc,val) => acc + parseFloat(val.cenasSam || 0),0)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g,"."))
      rows.push(aux)
      var aux = [null,"CENAS SAM (NO ALOJADOS)"]
      this.espacios.forEach(espacio => {
        aux.push(parseFloat(this.modelo.find(esp => esp.fecha == this.date && esp.idEspacio == espacio.idEspacio)?.cenasSamNo || 0).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, "."))
      });
      aux.push(parseFloat(this.modelo.reduce((acc,val) => acc + parseFloat(val.cenasSamNo || 0),0)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g,"."))
      rows.push(aux)
      var aux = [null,"TOTAL CLIENTES"]
      this.espacios.forEach(espacio => {
        aux.push(parseFloat(this.modelo.find(esp => esp.fecha == this.date && esp.idEspacio == espacio.idEspacio)?.clientes || 0).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, "."))
      });
      aux.push(parseFloat(this.modelo.reduce((acc,val) => acc + parseFloat(val.clientes || 0),0)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g,"."))
      rows.push(aux)
      var aux = [null,"ALOJADOS"]
      this.espacios.forEach(espacio => {
        aux.push(parseFloat(this.modelo.find(esp => esp.fecha == this.date && esp.idEspacio == espacio.idEspacio)?.alojados || 0).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, "."))
      });
      aux.push(parseFloat(this.modelo.reduce((acc,val) => acc + parseFloat(val.alojados || 0),0)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g,"."))
      rows.push(aux)
      var aux = [null,"F&B GUEST"]
      this.espacios.forEach(espacio => {
        aux.push(parseFloat(this.modelo.find(esp => esp.fecha == this.date && esp.idEspacio == espacio.idEspacio)?.fnbGuest || 0).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, "."))
      });
      aux.push(parseFloat(this.modelo.reduce((acc,val) => acc + parseFloat(val.fnbGuest || 0),0)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g,"."))
      rows.push(aux)
      var aux = [null,"Nº CAMAREROS"]
      this.espacios.forEach(espacio => {
        aux.push(parseFloat(this.modelo.find(esp => esp.fecha == this.date && esp.idEspacio == espacio.idEspacio)?.camareros || 0).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, "."))
      });
      aux.push(parseFloat(this.modelo.reduce((acc,val) => acc + parseFloat(val.camareros || 0),0)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g,"."))
      rows.push(aux)
      var aux = [null,"Nº COCINEROS"]
      this.espacios.forEach(espacio => {
        aux.push(parseFloat(this.modelo.find(esp => esp.fecha == this.date && esp.idEspacio == espacio.idEspacio)?.cocineros || 0).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, "."))
      });
      aux.push(parseFloat(this.modelo.reduce((acc,val) => acc + parseFloat(val.cocineros || 0),0)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g,"."))
      rows.push(aux)
      var aux = [null,"VENTAS POR CAMARERO"]
      this.espacios.forEach(espacio => {
        aux.push(parseFloat((this.modelo.find(mod => mod.idEspacio == espacio.idEspacio && mod.fecha == this.date)?.ventas || 0) / (this.modelo.find(mod => mod.idEspacio == espacio.idEspacio && mod.fecha == this.date)?.camareros || 1)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, "."))
      });
      aux.push(parseFloat(this.modelo.reduce((acc,val) => acc + parseFloat(val.ventas / (val.camareros || 1) || 0),0)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, "."))
      rows.push(aux)
      var aux = [null,"VENTAS POR COCINERO"]
      this.espacios.forEach(espacio => {
        aux.push(parseFloat((this.modelo.find(mod => mod.idEspacio == espacio.idEspacio && mod.fecha == this.date)?.ventas || 0) / (this.modelo.find(mod => mod.idEspacio == espacio.idEspacio && mod.fecha == this.date)?.cocineros || 1)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, "."))
      });
      aux.push(parseFloat(this.modelo.reduce((acc,val) => acc + parseFloat(val.ventas / (val.cocineros || 1) || 0),0)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, "."))
      rows.push(aux)
      var aux = [null,"CLIENTES POR CAMARERO"]
      this.espacios.forEach(espacio => {
        aux.push(parseFloat((this.modelo.find(mod => mod.idEspacio == espacio.idEspacio && mod.fecha == date)?.clientes || 0) / (this.modelo.find(mod => mod.idEspacio == espacio.idEspacio && mod.fecha == date)?.camareros || 1)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, "."))
      });
      aux.push(parseFloat(this.modelo.reduce((acc,val) => acc + parseFloat(val.clientes / (val.camareros || 1) || 0),0)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, "."))
      
      sheet.insertRows(2,rows)
      sheet.getCell("B2").alignment = { horizontal : 'center' }
      sheet.getCell("B2").border = { top:{style:'thick'}, bottom:{style:'thick'},left:{style:'thick'},right:{style:'thick'} }
      sheet.getCell("B8").alignment = { horizontal : 'center' }
      sheet.getCell("B8").border = { top:{style:'thick'}, bottom:{style:'thick'},left:{style:'thick'},right:{style:'thick'} }
      sheet.mergeCells("B2:I2")
      sheet.mergeCells("B8:I8")

      workbook.xlsx.writeBuffer()
      .then(buffer => FileSaver.saveAs(new Blob([buffer]), `REVENUE - EXPORTADO EL ${(new Date(new Date().getTime() + (new Date().getTimezoneOffset()*-1)*60000).toLocaleDateString('esp', {day:'numeric', month:'short', year:'numeric'}))}.xlsx`))
      .catch(err => console.log('Error writing excel export', err))
    },
    changeDate(d,event){
      this.loading = true
      if (new Date(d).getMonth() != new Date(event).getMonth() || new Date(d).getFullYear() != new Date(event).getFullYear()){
        this.$set(this, "date", event)
        this.getVentas()
      }
      else{
        this.$set(this, "date", event)
      }
      this.loading = false
      this.$forceUpdate()
    },
    async getVentas(){
      const { data } = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/diario/ventas/${new Date(this.date).getFullYear() + "-" + ("0"+(new Date(this.date).getMonth()+1)).slice(-2)}/`,
      });
      this.modelo = data
    },
    async setVentas(){
      try {
        await axios({
          method: 'POST',
          url: `${process.env.VUE_APP_API_URL}/diario/ventas/ppto/`,
          data: this.pptos,
        })
        await axios({
          method: 'POST',
          url: `${process.env.VUE_APP_API_URL}/diario/ventas/`,
          data: this.cambios,
        })
        this.$root.$emit("snack", "Se han guardado los cambios correctamente");
      } catch (err) {
        this.saving = false;
        this.$root.$emit("snack", "No se han podido guardar los cambios");
      }
    },
    change(espacio, key, val){
      if (this.modelo.some(mod => mod.idEspacio == espacio && mod.fecha == this.date)) this.$set(this.modelo.find(mod => mod.idEspacio == espacio && mod.fecha == this.date),key, val)
      else this.modelo.push({ idEspacio : espacio, fecha: this.date, [key]: val })
      if (this.cambios.some(mod => mod.idEspacio == espacio && mod.fecha == this.date)) this.$set(this.cambios.find(mod => mod.idEspacio == espacio && mod.fecha == this.date),key, val)
      else this.cambios.push({ idEspacio : espacio, fecha: this.date, [key]: val })
    },
    changePpto(espacio, val){
      if (this.pptos.some(ppto => ppto.idEspacio == espacio && ppto.fecha == new Date(this.date).getFullYear() + '-' + (('0'+new Date(this.date).getMonth()).slice(-2)))){
        this.$set(this.pptos.find(ppto => ppto.idEspacio == espacio && ppto.fecha == new Date(this.date).getFullYear() + '-' + (('0'+new Date(this.date).getMonth()).slice(-2))), 'ppto', val)
      }
      else{
        this.pptos.push({idEspacio : espacio, ppto: val, fecha: (new Date(this.date).getFullYear() + '-' + (('0'+new Date(this.date).getMonth()).slice(-2)))})
      }
    },
    // avgM(key){
    //   var arr = []
    //   if (this.cambios.filter(esp => esp.idEspacio == this.idEspacio && new Date(esp.fecha).toLocaleDateString('esp', {year:'numeric', month:'long'}) == new Date(this.date).toLocaleDateString('esp', {year:'numeric', month:'long'})).length > 0){
    //     arr = this.cambios.filter(esp => esp.idEspacio == this.idEspacio && new Date(esp.fecha).toLocaleDateString('esp', {year:'numeric', month:'long'}) == new Date(this.date).toLocaleDateString('esp', {year:'numeric', month:'long'}))
    //   }else{
    //     arr = this.modelo.filter(esp => esp.idEspacio == this.idEspacio && new Date(esp.fecha).toLocaleDateString('esp', {year:'numeric', month:'long'}) == new Date(this.date).toLocaleDateString('esp', {year:'numeric', month:'long'}))
    //   }
    //   return parseFloat(arr.reduce((acc,val) => parseFloat(acc) + parseFloat(val[key] || 0), 0) / this.days)
    // },
    // avgY(key){
    //   var arr = []
    //   if (this.cambios.filter(esp => esp.idEspacio == this.idEspacio && new Date(esp.fecha).toLocaleDateString('esp', {year:'numeric'}) == new Date(this.date).toLocaleDateString('esp', {year:'numeric'})).length > 0){
    //     arr = this.cambios.filter(esp => esp.idEspacio == this.idEspacio && new Date(esp.fecha).toLocaleDateString('esp', {year:'numeric'}) == new Date(this.date).toLocaleDateString('esp', {year:'numeric'}))
    //   }
    //   else{
    //     arr = this.modelo.filter(esp => esp.idEspacio == this.idEspacio && new Date(esp.fecha).toLocaleDateString('esp', {year:'numeric'}) == new Date(this.date).toLocaleDateString('esp', {year:'numeric'}))
    //   }
    //   return parseFloat(arr.reduce((acc,val) => parseFloat(acc) + parseFloat(val[key] || 0), 0) / this.days)
    // },
  },
};
</script>
<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.centered-input >>> input {
    text-align: center;
}
</style>